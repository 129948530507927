<template>
  <div>
    <script id="container" type="text/plain" :style="eSize"></script>
    <Modal
      v-model="modalSwicth.wrapper"
      :width="1050"
      :mask-closable="false"
      @on-cancel="modal_cancel('wrapper')"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>添加图片</span>
      </p>
      <i class="ivu-icon ivu-icon-ios-close" slot="close" @click="modal_cancel('wrapper')"></i>
      <div class="sc-content-wrapper">
        <div class="leftsec">
          <div class="demo-upload-list" v-for="item in imgList" :key="item.name">
            <template >
              <img :src="item.url" />
              <div class="demo-upload-list-cover" @click.self="handleChoose(item)">
                <span class="choose-icon" @click="handleChoose(item)">点击选择</span>
                <Icon type="ios-eye-outline" @click.native="handleView(item)" class="view-icon"></Icon>
                <Modal title="查看图片" v-model="modalSwicth.viewer" :width="800">
                  <img :src="viewImgUrl" v-if="modalSwicth.viewer" style="width: 100%" />
                </Modal>
                <Icon type="ios-trash-outline" @click.native="handleRemove(item)" class="del-icon"></Icon>
              </div>
            </template>
          </div>
          <Upload
            ref="upload"
            :show-upload-list="false"
            :on-success="handleSuccess"
            :format="['jpg','jpeg','png']"
            :max-size="2048"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize"
            :before-upload="handleBeforeUpload"
            multiple
            type="drag"
            :action="$url+'/img'"
            name="img"
            :data="uploadData"
            style="display: inline-block;width:158px;"
          >
            <div style="width: 158px;height:158px;padding-top: 58px;">
              <div style="line-height: 18px;">
                <p>上传新图片</p>
                <p>
                  <Icon type="ios-camera" size="20"></Icon>
                </p>
              </div>
            </div>
          </Upload>
          <div class="page-wrapper">
            <Page
              :total="Tpage.totalAmount"
              show-total
              show-elevator
              :current="Tpage.currentPage"
              :page-size="Tpage.currentSize"
              @on-change="changePage"
            />
          </div>
        </div>
        <div class="rightsec">
          <div class="choosed-img">
            <img :src="choosedImg.url" v-if="choosedImg.url" style="width: 100%" />
          </div>
          <div class="img-options">
            <label for="width">
              宽度：
              <input
                type="number"
                v-model="choosedImg.width"
                step="1"
                min="0"
                max="100"
                name="width"
                style="width:70px;"
                title="占页面宽度的比例"
              />
              <span title="占A4纸页面宽度的比例">%</span>
            </label>
            <p>（长宽比锁定）</p>
          </div>
        </div>
      </div>
      <div slot="footer">
        <Button
          size="large"
          type="primary"
          @click="modal_save('wrapper')"
          :disabled="!choosedImg.url"
          class="confirm-button"
        >确定</Button>
        <Button size="large" @click="modal_cancel('wrapper')" class="cancel-button">取消</Button>
      </div>
    </Modal>
    
  </div>
</template>
<script>

import "../../../static/UEditor/ueditor.config.js";
import "../../../static/UEditor/ueditor.all.js";
import "../../../static/UEditor/lang/zh-cn/zh-cn.js";


let userToken = localStorage.getItem("current_user_token");

export default {
  name: "uEditor",
  components: {
  },
  data() {
    return {
      editor: null,
      loadingStatus: false,
      ifDelData: false,
      modalSwicth: {
        wrapper: false,
        viewer: false,
        description:false,
        model:false
      },
      imgList: [],
      viewImgUrl: "",
      choosedImg:{
        url:"",
        width:50,
      },
      uploadData: {
        userToken: userToken,
        description: "",
        askData:'contentImg'
      },
      Tpage:{
        currentPage:1,
        currentSize:7,
        totalAmount:0
      },
      update_count:1,
      once:0
    };
  },
  model: {
    prop: "content",
    event: "change"
  },
  mounted() {
    let vm = this;
    
    window.UE.registerUI("插入图片", function(editor, uiName) {
      //注册按钮执行时的command命令，使用命令默认就会带有回退操作
      editor.registerCommand(uiName, {
        execCommand: function() {
          alert("execCommand:" + uiName);
        }
      });
      //创建一个button
      var btn = new window.UE.ui.Button({
        //按钮的名字
        name: uiName,
        //提示
        title: uiName,
        //添加额外样式，指定icon图标，这里默认使用一个重复的icon
        cssRules: "background-position: -381px 0;",
        //点击时执行的命令
        onclick: function() {
          //这里可以不用执行命令,做你自己的操作也可
          vm.modalSwicth.wrapper = true;
          vm.update_count++;
        }
      });
      return btn;
    });
    this.editor = window.UE.getEditor("container");
    this.editor.addListener("contentChange", function() {
      vm.$emit("change", vm.editor.getContent());
    });
  },
  props: {
    ec: {
      type: String,
      default: ""
    },
    eSize: {
      type: Object,
      default: function() {
        return {
          "min-height": "300pt",
          width: "594.3pt"
        };
      }
    },
    update:{
      type:Number,
      default:0
    },
    modelListName:{
      type:String,
      default:'modelList'
    }
  },
  beforeUpdate() {},
  watch: {
    // ec:function(val){
    //   let vm = this;
    //   this.editor.ready(function(){
    //     vm.editor.setContent(val);
    // });
    // },
    update:function(){
      let vm = this;
      this.editor.ready(function(){
          vm.editor.setContent(vm.ec);
      });
    },
    update_count:function(){
      this.getData();
    },
  },
  methods: {
    getData(){
      this.loading = true;
      this.$axios
        .post(this.$url + "/list", {
          page:{currentPage: this.Tpage.currentPage,currentSize: this.Tpage.currentSize,},
          userToken: userToken,
          selected_list:[],
          keyword: '',//关键字匹配哪一列由后端代码决定
          askData: 'contentImg'
        })
        .then(
          response => {
            if(response.data.stat==='ok'){
              this.imgList = [];
              for(let ele of response.data.res_record){
                ele.url = this.$fileUrlFacWithoutRight(ele.file_url);
                ele.name = ele.id;
                this.imgList.push(ele);
              }
              this.loading = false;
              this.Tpage.totalAmount = response.data.total_n;
            }else{
              this.$Message.error('请求数据错误！');
            }
          }
        )
        .catch(error=>{
          this.$Message.error(error+"服务器连接失败！请确认网络是否连接～")
        }
        );
    },
    modal_cancel(key) {
      this.modalSwicth[key] = false;
    },
    modal_save(key) {
      this.modalSwicth[key] = false;
      if(key==='wrapper'){
        let htmlObj = document.createElement("div");
        htmlObj.innerHTML = this.editor.getContent();
        let wrapperObj = document.createElement('h1');
        wrapperObj.style.cssText = "text-align:center;";
        wrapperObj.innerHTML = "<img src='"+this.$fileUrlFacWithoutRight(this.choosedImg.url)+"' width='"+this.choosedImg.width*5.94+"pt;'/>"
        htmlObj.appendChild(wrapperObj);
        this.editor.setContent(htmlObj.innerHTML);
      }
    },
    handleView(item) {
      this.viewImgUrl = item.url;
      this.modalSwicth.viewer = true;
    },
    handleChoose(item) {
      this.choosedImg.url = item.url;
    },
    changePage(page) {
      this.Tpage.currentPage = page;
      this.update_count++;
    },
    handleRemove(item) {
      let vm = this;
        let config = {
        title:'确认删除该图片?',
        onOk:function(){
          this.$axios
          .post(this.$url + "/img", {
            userToken: userToken,
            askData: 'del',
            pid: item.id
          })
          .then(response => {
            if (response.data.stat === "ok") {
              vm.imgList.splice(vm.imgList.findIndex(val=>val.name===item.name), 1);
              vm.$Message.success('删除成功!');
              vm.update_count ++;
            } else {
              vm.$Message.error(response.data.error_info);
              vm.update_count ++;
            }
          });
        },
        onCancel:function(){
        },
      };
      this.$Modal.confirm(config);
    },
    handleSuccess(res) {
      if (res.stat == "ok") {
        for (let ele of res.data) {
          ele.url = this.$fileUrlFacWithoutRight(ele.url);
          this.imgList.push(ele);
        }
      }
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: "图片格式不正确",
        desc:
          "图片" +
          file.name +
          "的格式不正确,请选择jpg或png格式的图片。"
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "文件大小超出范围",
        desc: "文件" + file.name + "太大,文件大小不应超出2M."
      });
    },
    handleBeforeUpload() {
      return true;
    },
    applyModel(value){
      if(this.modelListName==='modelList'){
        this.editor.setContent(JSON.parse(
        value.paragraph_content
      ).content.join(""));
      this.modalSwicth.model = false;
      }else{
        this.$emit('applyModel',value);
        this.modalSwicth.model = false;
      }
      
    }
  },

  destroyed() {
    window.UE.delEditor("container");
  },
  computed: {}
};
</script>

<style scoped>
@import "../../../static/UEditor/themes/default/css/ueditor.min.css";

table {
  border-collapse: collapse !important;
}

.sc-content-wrapper {
  min-height: 200px;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 20px;
}
.leftsec {
  width: 70%;
  text-align: left;
  border-right: 1px dashed #aaa;
  align-items:stretch;
}
.rightsec {
  width: 30%;
  padding-left: 30px;
  align-items:stretch;
}

.demo-upload-list {
  display: inline-block;
  width: 160px;
  height: 160px;
  text-align: center;
  line-height: 150px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
  cursor: pointer;
}
.demo-upload-list img {
  width: 100%;
  vertical-align: middle;
}
.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
.del-icon {
  position: absolute;
  right: 2px;
  bottom: 5px;
}
.view-icon {
  position: absolute;
  right: 2px;
  bottom: 35px;
}
.choose-icon {
  color: #fff;
  font-size: 14px;
}
.img-options {
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  line-height: 30px;
  
}
.img-options input {
  height: 25px;
  text-align: center;
  border: none;
  border-bottom: 1px solid #999;
  margin-right: 5px;
}
.img-options span {
  position: relative;
  right: 32px;
}
.page-wrapper {
  text-align: right;
  padding: 10px 20px;
}

textarea{
  width:100%;
  min-height:100px;
}
</style>